<template>
  <v-container fluid>
    <PageTitle :items="items" />

    <v-row>
      <v-col cols="12">
        <base-material-card>
          <template v-slot:heading>
            <v-row no-gutters>
              <v-col cols="10">
                <v-tabs
                  v-model="tab"
                  background-color="transparent"
                  slider-color="white"
                >
                  <v-tab> {{ $vuetify.lang.t("$vuetify.all") }}</v-tab>

                  <v-tab>
                    {{ $vuetify.lang.t("$vuetify.bankStatements") }}
                  </v-tab>

                  <v-tab>
                    {{ $vuetify.lang.t("$vuetify.xianjingshouzhi") }}
                  </v-tab>

                  <v-tab>
                    {{ $vuetify.lang.t("$vuetify.yinghangcunqu") }}
                  </v-tab>
                </v-tabs>
              </v-col>

              <v-col cols="2" class="d-flex align-center justify-end">
                <v-btn color="#2979ff" elevation="4" @click="handleDialog">
                  <v-icon left> mdi-account-plus </v-icon>
                  {{ $vuetify.lang.t("$vuetify.add") }}
                </v-btn>
              </v-col>
            </v-row>
          </template>

          <v-row>
            <v-col cols="12">
              <v-tabs-items v-model="tab" class="mt-4">
                <v-tab-item>
                  <v-row>
                    <v-spacer />

                    <v-col cols="12" lg="3" class="pb-0">
                      <v-text-field
                        :label="this.$vuetify.lang.t('$vuetify.search')"
                        v-model="search"
                        prepend-inner-icon="mdi-magnify"
                      />
                    </v-col>
                  </v-row>

                  <FinancialTable
                    :headers="allHeaders"
                    :datas="datas"
                    :search="search"
                    @edititem="handleEdit"
                    @deleteitem="handleDelete"
                    @copyitem="handleCopy"
                    @historyitem="handleHistory"
                  />
                </v-tab-item>

                <v-tab-item>
                  <v-row>
                    <v-spacer />

                    <v-col cols="12" lg="3" class="pb-0">
                      <v-text-field
                        :label="this.$vuetify.lang.t('$vuetify.search')"
                        v-model="search"
                        prepend-inner-icon="mdi-magnify"
                      />
                    </v-col>
                  </v-row>

                  <FinancialTable
                    :headers="bankHeaders"
                    :datas="bankObj"
                    :search="search"
                    @edititem="handleEdit"
                    @deleteitem="handleDelete"
                    @copyitem="handleCopy"
                    @historyitem="handleHistory"
                  />
                </v-tab-item>

                <v-tab-item>
                  <v-row>
                    <v-col cols="12" lg="4">
                      <v-radio-group
                        label="Select Options"
                        row
                        v-model="cashOptions"
                      >
                        <v-radio
                          :label="this.$vuetify.lang.t('$vuetify.all')"
                          value="3"
                        />

                        <v-radio
                          :label="this.$vuetify.lang.t('$vuetify.income')"
                          value="1"
                        />

                        <v-radio
                          :label="this.$vuetify.lang.t('$vuetify.expense')"
                          value="2"
                        />
                      </v-radio-group>
                    </v-col>

                    <v-spacer />

                    <v-col cols="12" lg="3" class="pb-0">
                      <v-text-field
                        :label="this.$vuetify.lang.t('$vuetify.search')"
                        v-model="search"
                        prepend-inner-icon="mdi-magnify"
                      />
                    </v-col>
                  </v-row>

                  <FinancialTable
                    :headers="cashHeaders"
                    :datas="cashFilter"
                    :showStats="2"
                    :search="search"
                    @edititem="handleEdit"
                    @deleteitem="handleDelete"
                    @copyitem="handleCopy"
                    @historyitem="handleHistory"
                  />
                </v-tab-item>

                <v-tab-item>
                  <v-row>
                    <v-col cols="12" lg="4">
                      <v-radio-group
                        label="Select Options"
                        row
                        v-model="depositOptions"
                      >
                        <v-radio
                          :label="this.$vuetify.lang.t('$vuetify.all')"
                          value="3"
                        />

                        <v-radio
                          :label="this.$vuetify.lang.t('$vuetify.deposit')"
                          value="1"
                        />

                        <v-radio
                          :label="this.$vuetify.lang.t('$vuetify.withdraw')"
                          value="2"
                        />
                      </v-radio-group>
                    </v-col>

                    <v-spacer />

                    <v-col cols="12" lg="3" class="pb-0">
                      <v-text-field
                        :label="this.$vuetify.lang.t('$vuetify.search')"
                        v-model="search"
                        prepend-inner-icon="mdi-magnify"
                      />
                    </v-col>
                  </v-row>

                  <FinancialTable
                    :headers="depositHeaders"
                    :datas="depositeFilter"
                    :showStats="3"
                    :search="search"
                    @edititem="handleEdit"
                    @deleteitem="handleDelete"
                    @copyitem="handleCopy"
                    @historyitem="handleHistory"
                  />
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>

    <FinancialCRUD
      :dialog.sync="dialog"
      :bankAccountList="bankList"
      :obj="myobj"
      @editItem="handleEditConfirm"
    />

    <DeleteDialog
      :dialogDelete.sync="dialogDelete"
      :deleteObj="myobj"
      @handleDelete="handleDeleteConfirm"
    />

    <History
      :dialogHistory.sync="dialogHistory"
      :obj="myobj"
      :dataType="dataType"
    />
  </v-container>
</template>

<script>
import {
  financeLogDelete,
  financeLogUpsert,
  financeLogList,
  bankAccountList,
} from "@/api/financial";
import { profilePicUpload } from "@/api/company";

const clone = (obj) => Object.assign({}, obj);
const renameKey = (object, key, newKey) => {
  const clonedObj = clone(object);
  const targetKey = clonedObj[key];

  delete clonedObj[key];
  clonedObj[newKey] = targetKey;
  return clonedObj;
};

export default {
  name: "FinancialLog",
  components: {
    FinancialTable: () => import("@/components/financial/financialTable"),
    PageTitle: () => import("@/components/pageNavigation"),
    FinancialCRUD: () => import("@/components/financial/financialCRUD"),
    DeleteDialog: () => import("@/components/deleteDialog"),
    History: () => import("@/components/financial/history"),
  },
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      dialogHistory: false,
      dataType: "finance_log",
      modal: false,
      wait: false,
      tab: 0,
      search: "",
      depositOptions: "3",
      cashOptions: "3",
      tempFile: [],
      datas: [],
      bankObj: [],
      cashObj: [],
      depositObj: [],
      filterData: [],
      dates: [
        this.moment().format("YYYY-MM-DD"),
        this.moment().endOf("month").format("YYYY-MM-DD"),
      ],
      items: [
        { text: this.$vuetify.lang.t("$vuetify.home") },
        { text: this.$vuetify.lang.t("$vuetify.financeLog") },
      ],
      searchForm: {
        key: "",
        skip: 0,
        limit: 999999,
        type: "",
        bt: "",
        et: "",
        in_out: 0, // 1、收入 2、支出
      },
      myobj: {
        id: "",
        type: 1, // 1 银行对账单 2 现金收支 3 银行存取款 类型
        in_out: 1, // (现金)1收入 2 支出 （银行）1存款 2取款  type=1 不显示
        amount: 0, // 美金
        kh: 0, // 柬币
        bank_name: "", // 银行名
        bank_account: "", // 银行账号
        date: "", // 日期
        extend: "", // {key: value, key: value} json 明细 放在现金下面
        note: "", // 备注
        attach: [], // 附件 相关文档 []
      },
      extend: {
        key: "",
        value: "",
      },
      fileList: [],
      updataRule: {
        // company: [
        //   { required: true, message: this.$t('all.enterFactoryName'), trigger: 'change' }
        // ]
      },
      factorySearch: {
        key: "",
        skip: "",
        limit: "",
      },
      allHeaders: [
        {
          text: this.$vuetify.lang.t("$vuetify.itemNo"),
          value: "itemNo",
          sortable: false,
          width: "100",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.code"),
          value: "po",
          sortable: true,
          width: "100",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.date"),
          value: "date",
          sortable: true,
          width: "150",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.category"),
          value: "category",
          sortable: false,
          width: "100",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.xiangqing"),
          value: "details",
          sortable: false,
          width: "350",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.Amount"),
          value: "amount",
          sortable: true,
          width: "250",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.addTime"),
          value: "gen_time",
          sortable: true,
          width: "150",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.opt"),
          value: "actions",
          sortable: false,
          width: "150px",
          align: "center",
        },
      ],
      depositHeaders: [
        {
          text: this.$vuetify.lang.t("$vuetify.itemNo"),
          value: "itemNo",
          sortable: false,
          width: "80px",
          align: "center",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.code"),
          value: "po",
          sortable: true,
          width: "100px",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.date"),
          value: "date",
          sortable: true,
          width: "100px",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.xiangqing"),
          value: "details",
          sortable: false,
          width: "350px",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.Amount"),
          value: "amount",
          sortable: false,
          width: "180px",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.note"),
          value: "note",
          sortable: false,
          width: "250px",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.addTime"),
          value: "gen_time",
          sortable: true,
          width: "100px",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.opt"),
          value: "actions",
          sortable: false,
          width: "150px",
          align: "center",
        },
      ],
      bankHeaders: [
        {
          text: this.$vuetify.lang.t("$vuetify.itemNo"),
          value: "itemNo",
          sortable: false,
          width: "80px",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.code"),
          value: "po",
          sortable: true,
          width: "100px",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.date"),
          value: "date",
          sortable: true,
          width: "130px",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.xiangqing"),
          value: "details",
          sortable: false,
          width: "350px",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.Amount"),
          value: "amount",
          sortable: true,
          width: "200px",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.note"),
          value: "note",
          sortable: false,
          width: "100px",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.addTime"),
          value: "gen_time",
          sortable: true,
          width: "130px",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.opt"),
          value: "actions",
          sortable: false,
          width: "150px",
          align: "center",
        },
      ],
      cashHeaders: [
        {
          text: this.$vuetify.lang.t("$vuetify.itemNo"),
          value: "itemNo",
          sortable: false,
          width: "80px",
          align: "center",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.code"),
          value: "po",
          sortable: true,
          width: "100px",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.date"),
          value: "date",
          sortable: true,
          width: "100px",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.xiangqing"),
          value: "details",
          sortable: false,
          width: "300px",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.Amount"),
          value: "amount",
          sortable: false,
          width: "200px",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.note"),
          value: "note",
          sortable: false,
          width: "200px",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.addTime"),
          value: "gen_time",
          sortable: true,
          width: "100px",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.opt"),
          value: "actions",
          align: "center",
          sortable: false,
          width: "150px",
        },
      ],
      bankList: [],
    };
  },
  methods: {
    getData() {
      financeLogList(this.searchForm).then((res) => {
        this.datas = res.items;
        console.log(res.items[0]);

        this.bankObj = this.datas.filter((p) => p.type == 1);
        this.cashObj = this.datas.filter((p) => p.type == 2);
        this.depositObj = this.datas.filter((p) => p.type == 3);

        this.datas.forEach((item, key) => {
          item.itemNo = key + 1;
          this.datas[key] = renameKey(this.datas[key], "usd", "amount");
        });

        this.bankObj.forEach((item, key) => {
          item.itemNo = key + 1;
          this.bankObj[key] = renameKey(this.bankObj[key], "usd", "amount");
        });

        this.cashObj.forEach((item, key) => {
          item.itemNo = key + 1;
          this.cashObj[key] = renameKey(this.cashObj[key], "usd", "amount");
        });

        this.depositObj.forEach((item, key) => {
          item.itemNo = key + 1;
          this.depositObj[key] = renameKey(
            this.depositObj[key],
            "usd",
            "amount"
          );
        });
      });

      bankAccountList().then((res) => {
        this.bankList = [...res.items];
      });
    },
    handleDialog() {
      this.dialog = true;
      this.myobj = {
        id: "",
        type: 1, // 1 银行对账单 2 现金收支 3 银行存取款 类型
        in_out: 1, // (现金)1收入 2 支出 （银行）1存款 2取款  type=1 不显示
        usd: 0, // 美金
        kh: 0, // 柬币
        bank_name: "", // 银行名
        bank_account: "", // 银行账号
        date: "", // 日期
        extend: "", // {key: value, key: value} json 明细 放在现金下面
        note: "", // 备注
        attach: "", // 附件 相关文档 []
      };
    },
    handleEdit(obj) {
      if (this.permissionCheck("modify")) {
        this.dialog = true;
        this.myobj = obj;
      }
    },
    handleEditConfirm(obj, files) {
      this.dialog = false;

      const arr = [];
      var tempFile = [];

      if (obj.attach.length > 0)
        obj.attach.forEach((p) => tempFile.push(p.md5));

      if (files != undefined) {
        files.forEach((p) => {
          arr.push(this.uploadFile(p));
        });

        Promise.all(arr)
          .then((res) => {
            res.forEach((p) => tempFile.push(p));
          })
          .finally(() => {
            obj.attach = JSON.stringify(tempFile);
            this.myobj = obj;
            this.wait = true;
          });
      } else {
        obj.attach = JSON.stringify(tempFile);

        this.myobj = obj;
        this.wait = true;
      }
    },
    handleCopy(obj) {
      obj.id = "";
      this.myobj = obj;
      this.dialog = true;
      this.getData();
    },
    handleHistory(obj) {
      this.myobj = obj;
      this.dialogHistory = true;
    },
    async uploadFile(file) {
      var arr = "";

      const fileForm = new FormData();
      fileForm.append("file", file);

      await profilePicUpload(fileForm)
        .then((res) => {
          arr = res.data.md5;
        })
        .catch((err) => console.log("Finanical File Error", err));

      return arr;
    },
    handleDelete(obj) {
      this.dialogDelete = true;
      this.myobj = obj;
    },
    handleDeleteConfirm(obj) {
      this.dialogDelete = false;

      financeLogDelete(obj.id)
        .then(() => this.getData())
        .catch((err) => console.log("Financial Delete", err));
    },
    myFilter(option) {
      let temp = [];

      switch (option) {
        case 1:
          if (this.depositOptions != 3) {
            temp = this.depositObj.filter(
              (p) => p.in_out == this.depositOptions
            );
          } else {
            temp = this.depositObj;
          }
          break;
        case 2:
          if (this.cashOptions != 3) {
            temp = this.cashObj.filter((p) => p.in_out == this.cashOptions);
          } else {
            temp = this.cashObj;
          }
          break;
        default:
          console.log("no case match");
          break;
      }

      return temp;
    },
  },
  mounted() {
    this.getData();
  },
  computed: {
    depositeFilter: function () {
      return this.myFilter(1);
    },
    cashFilter: function () {
      return this.myFilter(2);
    },
    dateRangeText: {
      get: function () {
        return this.dates.join(" ~ ");
      },
      set: function (value) {
        this.dates = value.includes("~")
          ? [
              this.moment().format("YYYY-MM-DD"),
              this.moment().endOf("month").format("YYYY-MM-DD"),
            ]
          : value;
      },
    },
  },
  watch: {
    dates: function () {
      // const temp = this.filterData.filter(
      //   p =>
      //     this.moment(p.gen_time).format("YYYY-MM-DD") <=
      //       this.moment(this.dates[1]).format("YYYY-MM-DD") &&
      //     this.moment(p.gen_time).format("YYYY-MM-DD") >=
      //       this.moment(this.dates[0]).format("YYYY-MM-DD")
      // );
      // this.filterData = temp;
      // this.myFilter();
    },
    tab: function () {
      // console.log(this.tab);
    },
    wait: function () {
      if (this.wait == true) {
        this.myobj.usd = this.myobj.amount;

        financeLogUpsert(this.myobj)
          .then(() => {
            this.getData();
            this.wait = false;
          })
          .catch((err) => console.log("new Company Error", err));
      }
    },
  },
};
</script>