import request from '@/utils/request'

// 银行账号列表
export function bankAccountList(data) {
  return request({
    url: '/bank_account/list',
    method: 'post',
    data: data
  })
}

// 银行账号修改或添加
export function bankAccountUpsert(data) {
  return request({
    url: '/bank_account/upsert',
    method: 'post',
    data: data
  })
}

// 银行账号删除
export function bankAccountDel(id) {
  return request({
    url: '/bank_account/delete/' + id,
    method: 'delete'
  })
}

// 收款记录列表
export function orderChargeList(data) {
  return request({
    url: '/order_charge/list',
    method: 'post',
    data: data
  })
}
// 增加收款记录
export function orderChargeUpsert(data) {
  return request({
    url: '/order_charge/upsert',
    method: 'post',
    data: data
  })
}

// 删除收款记录
export function orderChargeDel(id) {
  return request({
    url: '/order_charge/delete/' + id,
    method: 'delete'
  })
}

export function parmentList() {
  return request({
    url: '/account_period/list',
    method: 'get'
  })
}

export function parmentUpsert(data) {
  return request({
    url: '/account_period/upsert',
    method: 'post',
    data: data
  })
}

export function delParment(id) {
  return request({
    url: '/account_period/delete/' + id,
    method: 'delete'
  })
}

export function payedList(data) {
  return request({
    url: '/order_payed/list',
    method: 'post',
    data: data
  })
}

export function upsertPayed(data) {
  return request({
    url: '/order_payed/upsert',
    method: 'post',
    data: data
  })
}

export function deletePayed(id) {
  return request({
    url: '/order_payed/delete/' + id,
    method: 'delete'
  })
}

export function payedInfo(id) {
  return request({
    url: '/order_payed/info/' + id,
    method: 'get'
  })
}

export function payedCancel(id) {
  return request({
    url: '/order_payed/cancel/' + id,
    method: 'get'
  })
}

export function payedPay(data) {
  return request({
    url: '/order_payed/pay',
    method: 'post',
    data: data
  })
}

export function orderStat(data) {
  return request({
    url: '/order/stat',
    method: 'post',
    data: data
  })
}

// 费用日志添加、修改
export function feeLogUpsert(data) {
  return request({
    url: '/fee_log/upsert',
    method: 'post',
    data: data
  })
}
// 费用日志详情
export function feeLogInfo(id) {
  return request({
    url: '/fee_log/info/' + id,
    method: 'get'
  })
}

// 费用日志列表
export function feeLogList(data) {
  return request({
    url: '/fee_log/list',
    method: 'post',
    data: data
  })
}

// 费用分类删除
export function feeTypesDelete(id) {
  return request({
    url: '/fee_types/delete/' + id,
    method: 'delete'
  })
}

// 费用分类列表
export function feeTypesList(data) {
  return request({
    url: '/fee_types/list',
    method: 'get',
    params: data
  })
}

// 费用分类
export function feeTypesUpsert(data) {
  return request({
    url: '/fee_types/upsert',
    method: 'post',
    data: data
  })
}

// 费用列表删除
export function feeLogDelete(id) {
  return request({
    url: '/fee_log/delete/' + id,
    method: 'delete'
  })
}

// 财务日志添加
export function financeLogUpsert(data) {
  return request({
    url: '/finance_log/upsert',
    method: 'post',
    data: data
  })
}

// 财务日志列表
export function financeLogList(data) {
  return request({
    url: '/finance_log/list',
    method: 'post',
    data: data
  })
}

// 财务日志删除
export function financeLogDelete(id) {
  return request({
    url: '/finance_log/delete/' + id,
    method: 'delete'
  })
}

// 数据修改日志
export function modifyLogList(data) {
  return request({
    url: '/modify_log/list',
    method: 'post',
    data: data
  })
}
